<!--
 * @Description: 设备管理-设备管理-设备列表
 * @Author: kecraft
 * @Date: 2024-01-10 14:04:21
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 16:27:00
 * @FilePath: /impact-iotos-console/src/views/device/device/deviceList.vue
-->
<template>
  <div class="device-list">
    <div class="search-conatiner">
      <div class="form-item">
        <el-input v-model="query.keyword" class="input-item" placeholder="请输入设备名称/设备ID" :prefix-icon="Search"
          @keyup.enter="search" />
      </div>
      <div class="form-item">
        <el-select v-model="query.deviceType" placeholder="请选择节点类型" class="width200" @change="search" clearable>
          <el-option v-for="item in deviceTypeList" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </div>
      <div class="form-item">
        <el-select v-model="query.online" placeholder="请选择设备状态" class="width200" @change="search" clearable>
          <el-option v-for="item in onlineList" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </div>
      <div class="form-item">
        <div class="search-btn" @click="search" v-domPreventReClick>搜索</div>
      </div>
      <div class="form-item">
        <div class="reset-btn" @click="reset" v-domPreventReClick>重置</div>
      </div>
    </div>
    <div class="btns-container">
      <div>
        <div class="btn-green" @click="() => isAdd = true" v-domPreventReClick>
          <img src="@/assets/icon/add.png">
          创建设备
        </div>
      </div>
      <div class="btn-groups">
        <div class="btn-normal" @click="() => isBatchAdd = true" v-domPreventReClick>
          <img src="@/assets/icon/import.png">
          批量添加
        </div>
        <div class="btn-normal" v-if="query.pk && query.pk !== 'all'" @click="uploadByPk" v-domPreventReClick>
          <img src="@/assets/icon/upload.png">
          按产品导出
        </div>
        <div class="btn-default" v-else>
          <img src="@/assets/icon/upload.png">
          按产品导出
        </div>
        <div class="btn-normal" v-if="selectList.length !== 0" @click="batchDel" v-domPreventReClick>
          <img src="@/assets/icon/delect.png">
          删除
        </div>
        <div class="btn-default" v-else click="batchDel">
          <img src="@/assets/icon/delect.png">
          删除
        </div>
      </div>
    </div>
    <div class="tables">
      <el-table stripe :data="deviceList" empty-text="暂无数据" v-loading="isload" style="width: 100%;"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column min-width="120" label="设备名称" prop="name" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="设备ID" prop="devId" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="所属产品" prop="productName" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="节点类型" prop="deviceTypeStr" align="left">
          <template #default="scope">
            <div class="deviceType-container">
              <img src="@/assets/product/product_1.png" v-if="scope.row.deviceType === 'GATEWAY'">
              <img src="@/assets/product/product_2.png" v-if="scope.row.deviceType === 'TERMINAL'">
              <img src="@/assets/product/product_3.png" v-if="scope.row.deviceType === 'GENERAL'">
              <img src="@/assets/product/product_4.png" v-if="scope.row.deviceType === 'SWITCH'">
              <div>{{ scope.row.deviceTypeStr }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="设备状态" prop="online" align="center">
          <template #default="scope">
            <div class="status-container" v-if="!scope.row.online">
              <div class="icon"></div>
              <div>离线</div>
            </div>
            <div class="status-container" v-else>
              <div class="icon1"></div>
              <div>在线</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="120" label="是否启用" prop="enable" align="center">
          <template #default="scope">
            <div class="status-container">
              <el-switch v-model="scope.row.enable" class="ml-2"
                style="--el-switch-on-color: #13ce66;margin-right:10px;" @change="handleSwitchChange(scope.row)" />
              {{ scope.row.enable ? "已启用" : "已禁用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="160" label="最后上线时间" prop="loginTime" align="center" />
        <el-table-column width="220" label="操作" align="center">
          <template #default="scope">
            <div class="column-btn">
              <div class="options">
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleDetail(scope.row)"
                  v-domPreventReClick>
                  查看
                </span>
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleDetail1(scope.row)"
                  v-domPreventReClick>
                  控制
                </span>
                <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(scope.row)"
                  v-domPreventReClick>
                  删除
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fooner-conatiner">
      <pagination :total="total" @pageChange="getList" class="pagination" />
    </div>
    <deviceAdd v-if="isAdd" @close="handleAddClose" />
    <batchDeviceAdd v-if="isBatchAdd" @close="handleBatchAddClose" />
  </div>
</template>

<script setup>
import deviceAdd from './deviceAdd';
import batchDeviceAdd from './batchDeviceAdd';
import { Search } from '@element-plus/icons-vue';
import pagination from "@/components/control/pagination";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref, watch, defineProps, onMounted } from 'vue';
import api from '@/api/api';
import { deviceTypeList, onlineList } from '@/hooks/deviceEnum';
import { enumFilter, uploadCsvFile } from "@/hooks/common";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const props = defineProps(['productKey', "pkList"])
watch(() => props.productKey, (newVal) => {
  query.pk = newVal;
  console.log(newVal);
  search();
});
const productList = ref([]);
watch(() => props.pkList, (newVal) => {
  productList.value = newVal
});
onMounted(() => {
  query.pk = props.productKey;
  const selectPk = store.state.vuexTest.pk;
  if (!selectPk) {
    search();
  }
})
const total = ref(0);
const isAdd = ref(false);
const isload = ref(false);
const isBatchAdd = ref(false);
const selectList = ref([]); // 选中的列表
const deviceList = ref([]);
const query = reactive({
  keyword: "",
  deviceType: "",
  online: "",
  pk: "",
  page: 0,
  size: 10,
})
const search = () => {
  isload.value = true;
  if (!isload.value) return;
  if (query.pk === "all") {
    query.pk = ""
  }
  api.getDeviceList(query).then(res => {
    if (res.code === "0") {
      const { totalElements, content } = res.res.data;
      total.value = totalElements;
      content.forEach(item => {
        item.deviceTypeStr = enumFilter(item.deviceType, deviceTypeList)
      })
      deviceList.value = content;
      isload.value = false;
    }
  })
}


const reset = () => {
  query.keyword = query.deviceType = query.online = "";
  search();
}
const handleSelectionChange = (val) => {
  selectList.value = val;
}
const handleDetail = (row) => {
  const { pk, devId } = row
  sessionStorage.removeItem("handleDeviceTabsSelect");
  router.push({
    path: "/product/device/details",
    query: { pk, devId }
  })
}
const handleDetail1 = (row) => {
  const { pk, devId } = row
  sessionStorage.setItem("handleDeviceTabsSelect", "设备控制");
  router.push({
    path: "/product/device/details",
    query: { pk, devId }
  })
}

const handleSwitchChange = (row) => {
  const { pk, devId } = row;
  if (!row.enable) {
    // 禁用
    api.disableDevice(pk, devId).then(res => {
      if (res.code === "0") {
        ElMessage.success("禁用成功！")
      } else {
        deviceList.value.forEach(item => {
          if (item.pk === pk && item.devId === devId) {
            item.enable = true;
          }
        })
      }
    })
  } else {
    // 启用
    api.enableDevice(pk, devId).then(res => {
      if (res.code === "0") {
        ElMessage.success("启用成功！")
      } else {
        deviceList.value.forEach(item => {
          if (item.pk === pk && item.devId === devId) {
            item.enable = false;
          }
        })
      }
    })
  }
}

const handleDel = (item) => {
  ElMessageBox.confirm("确定删除该设备吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const delList = []
    const { pk, devId } = item;
    const newItem = {
      pk, devId
    }
    delList.push(newItem)
    api.batchDelDevice(delList).then(res => {
      if (res.code === "0") {
        ElMessage.success("删除成功！")
        selectList.value = []
        search();
      }
    })
  })
}

const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}
const handleAddClose = (v) => {
  isAdd.value = false;
  if (v) {
    search();
  }
}

const handleBatchAddClose = (v) => {
  isBatchAdd.value = false;
  if (v) {
    search();
  }
}
/**
 * 批量删除
 */
const batchDel = () => {
  if (selectList.value.length === 0) return;
  ElMessageBox.confirm("确定删除这些设备吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const list = selectList.value;
    const delList = []
    list.forEach(item => {
      const { pk, devId } = item;
      const newItem = {
        pk, devId
      }
      delList.push(newItem)
    })
    api.batchDelDevice(delList).then(res => {
      if (res.code === "0") {
        ElMessage.success("删除成功！")
        selectList.value = []
        search();
      }
    })
  })
}

const uploadByPk = () => {
  if (!query.pk || query.pk === 'all' && isload && deviceList.value.length === 0) return;
  api.exportDeviceByPk(query.pk).then(res => {
    if (res.code === "0") {
      const str = res.res.data;
      uploadCsvFile(str);
    }
  })
}
</script>

<style lang="less" scoped>
.deviceType-container {
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}

.width200 {
  width: 200px;
}

.width260 {
  width: 260px;
}

.search-conatiner {
  display: flex;
  align-items: center;

  .form-item {
    margin-right: 30px;
  }
}

.fooner-conatiner {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.device-list {
  box-sizing: border-box;
  // padding: 10px 20px;
}

.input-item {
  width: 260px;
  height: 36px;
}

.search-btn {
  cursor: pointer;
  width: 112px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  box-sizing: border-box;
  background: #367CC5;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
}

.search-btn:hover,
.search-btn:active {
  background: #015ee0;
  color: #fff;
}

.reset-btn {
  cursor: pointer;
  width: 112px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}

.btns-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-groups {
    display: flex;
    align-items: center;

    &>div {
      margin-left: 10px;
    }
  }
}

.btn-green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}


.btn-normal {
  box-sizing: border-box;
  border: 1px solid #B7BBC0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  color: #333333;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.btn-default {
  box-sizing: border-box;
  border: 1px solid #B7BBC0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  color: #333333;
  font-size: 16px;
  font-weight: lighter;
  opacity: 0.5;
  cursor: not-allowed;

  &>img {
    width: 18px;
    height: 18px;
    opacity: 0.5;
    margin-right: 4px;
  }
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #878CAA;
  box-sizing: border-box;
  margin-right: 10px;
  background: rgba(135, 140, 170, 0.5);
}

.icon::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #878CAA;
}

.icon1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #0EB463;
  box-sizing: border-box;
  margin-right: 10px;
  background: rgba(14, 180, 99, 0.5);
}

.icon1::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0EB463;
}

.tables {
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>