<!--
 * @Description: 设备管理-入口
 * @Author: kecraft
 * @Date: 2024-01-03 10:54:49
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-21 10:35:58
 * @FilePath: /impact-iotos-console/src/views/device/index.vue
-->
<template>
  <div class="device-homePage">
    <div>
      <div class="search-container">
        <div class="form-item">
          <span class="form-label">选择产品</span>
          <el-select v-model="pk" placeholder="请选择产品" class="width200" @change="getCount">
            <el-option v-for="item in pkList" :key="item.pk" :label="item.name" :value="item.pk" />
          </el-select>
        </div>
        <!-- 设备情况 -->
        <div class="chart-container">
          <div>
            <div class="icon"></div>
            <div class="item-text">设备总数：{{ info && info.deviceAll || 0 }}</div>
          </div>
          <div>
            <div class="icon1"></div>
            <div class="item-text">激活总数：{{ info && info.deviceActivated || 0 }}</div>
          </div>
          <div>
            <div class="icon2"></div>
            <div class="item-text">在线数：{{ info && info.deviceOnline || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="list-conatiner">
        <div class="container">
          <div class="big-title">
            <div></div>
            设备管理
          </div>
          <div>
            <deviceList v-if="selectName === '0'" :productKey="pk" :pkList="pkList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import deviceList from "./device/deviceList"
import api from '@/api/api'
import { ref, onBeforeUnmount } from 'vue';
import { useStore } from "vuex";
const store = useStore();
const pk = ref("");
const pkList = ref([]);
const selectName = ref("0");

const info = ref([]);
const getCount = () => {
  const productKey = pk.value === "all" ? "" : pk.value
  api.getCountTotalDevice({
    pk: productKey
  }).then(res => {
    if (res.code === "0") {
      info.value = res.res.data;
    }
  })
}

const getProducts = () => {
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      const all = {
        pk: "all",
        name: "全部",
      }
      pk.value = "all"
      pkList.value = [all, ...res.res.data];
      const selectPk = store.state.vuexTest.pk;
      if (selectPk) {
        pk.value = selectPk
      }
      getCount()
    }
  })
}

getProducts();
/**
 * 切换pk
 */

onBeforeUnmount(() => {
  store.dispatch("vuexTest/changePkValue", "");
})
</script>

<style lang="less" scoped>
.list-conatiner {
  box-sizing: border-box;
  padding: 10px;
  background: #FAFAFB;

  .container {
    background: #ffffff;
    box-sizing: border-box;
    padding: 17px 35px;

    .big-title {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #367CC5;
      margin-bottom: 30px;

      &>div {
        width: 3px;
        height: 17px;
        background: #367CC5;
        margin-right: 7px;
      }
    }
  }
}

.width200 {
  width: 200px;
}

.search-container {
  box-sizing: border-box;
  padding: 14px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;

  .form-label {
    font-size: 16px;
    color: #606266;
    margin-right: 10px;
  }
}

.search-container1 {
  box-sizing: border-box;
  padding: 0 20px;
}

.chart-container {
  // top: 50px;
  // right: 60px;
  // position: absolute;
  display: flex;

  &>div {
    display: flex;
    align-items: center;
  }

  .item-text {
    color: #7D7F8C;
  }

  .icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #1789F9;
    box-sizing: border-box;
    margin-left: 30px;
    margin-right: 10px;
    background-color: rgba(23, 137, 249, 0.5);
    // box-shadow: inset 0 0 4px 0 rgba(23, 137, 249, .5);
  }

  .icon::before {
    position: absolute;
    left: 4px;
    top: 4px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #1789F9;
  }

  .icon1 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #A027E6;
    box-sizing: border-box;
    margin-left: 30px;
    margin-right: 10px;
    background: rgba(160, 39, 230, 0.50);
  }

  .icon1::before {
    position: absolute;
    left: 4px;
    top: 4px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #A027E6;
  }

  .icon2 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #0EB463;
    box-sizing: border-box;
    margin-left: 30px;
    margin-right: 10px;
    background: rgba(14, 180, 99, 0.50);
  }

  .icon2::before {
    position: absolute;
    left: 4px;
    top: 4px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #0EB463;
  }
}

.device-homePage {
  box-sizing: border-box;

  &>div {
    min-height: 90vh;
    background: #FAFAFB;
  }
}

.form-item {
  display: flex;
  align-items: center;

  .input-item {
    width: 300px;
    height: 36px;
  }

  .search-btn {
    margin-left: 10px;
    cursor: pointer;
    width: 66px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    box-sizing: border-box;
    border: 1px solid #015ee0;
    font-size: 14px;
    color: #015ee0;
  }
}

.btn-groups :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.search-btn:hover,
.search-btn:active {
  background: #015ee0;
  color: #fff;
}
</style>