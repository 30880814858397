<!--
 * @Description: 设备管理-设备创建
 * @Author: kecraft
 * @Date: 2024-01-10 14:54:06
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-24 10:24:38
 * @FilePath: /impact-iotos-console/src/views/device/device/deviceAdd.vue
-->
<template>
  <div class="device-add">
    <myDialog @close="handleClose" @ok="handleOk" title="创建设备" width="700px">
      <el-form :rules="rules" ref="formDeviceAdd" :model="form" label-position="left">
        <el-form-item label="所属产品：" prop="pk" class="label-feather">
          <el-select v-model="form.pk" placeholder="请选择所属产品" class="width500" filterable>
            <el-option v-for="item in productList" :key="item.pk" :label="item.name" :value="item.pk" />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称：" prop="name" class="label-feather">
          <el-input v-model="form.name" class="width500" placeholder="请输入设备名称" />
        </el-form-item>
        <el-form-item label="设备标识：" prop="devId" class="label-feather">
          <el-input v-model="form.devId" class="width500" placeholder="请输入设备标识" />
        </el-form-item>
      </el-form>
      <div class="tooltip">设备ID即为设备标识，必须唯一，如MEI、MAC等。如果产品为NB-IoT设备，请用MEI。</div>
    </myDialog>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import myDialog from "@/components/dialog/myDialog.vue";
import { reactive, ref, defineEmits, onMounted } from "vue";
import api from '@/api/api';
const emit = defineEmits(["close"]);
const form = reactive({
  pk: "", // 所属产品
  name: "", // 设备名称
  devId: "", // 设备标识
})
const productList = ref([]);
onMounted(() => {
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      productList.value = res.res.data;
    }
  })
})
const rules = {
  name: [
    { required: true, message: "设备名称不能为空", trigger: "change" },
    {
      min: 2,
      max: 32,
      message: '长度在 2 到 32 个字符',
      trigger: 'blur'
    }
  ],
  pk: [
    { required: true, message: "所属产品不能为空", trigger: "change" },
  ],
  devId: [
    { required: true, message: "设备标识不能为空", trigger: "change" },
    {
      min: 4,
      max: 32,
      message: '长度在 4 到 32 个字符',
      trigger: 'blur'
    }
  ],
}
const handleClose = () => {
  emit("close", false)
}
const formDeviceAdd = ref(null);
const handleOk = () => {
  formDeviceAdd.value.validate((valid) => {
    if (valid) {
      api.addDevice(form).then(res => {
        if (res.code === "0") {
          ElMessage.success("创建成功！")
          emit("close", true)
        }
      })
    } else {
      return false;
    }
  });
}
</script>

<style lang="less" scoped>
.tooltip {
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  padding-left: 100px;
}

.width500 {
  width: 500px;
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}
</style>