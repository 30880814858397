<!--
 * @Description: 设备管理 - 批量新增
 * @Author: kecraft
 * @Date: 2024-01-12 09:14:50
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 09:51:32
 * @FilePath: /impact-iotos-console/src/views/device/device/batchDeviceAdd.vue
-->
<template>
  <div class="batch-add">
    <myDialog @close="handleClose" @ok="handleOk" title="批量添加设备">
      <div class="dialog-box">
        <el-form :rules="rules" ref="formBatchDeviceAdd" :model="form" label-position="left">
          <el-form-item label="所属产品：" prop="pk" class="label-feather">
            <el-select v-model="form.pk" placeholder="请选择所属产品" class="width500">
              <el-option v-for="item in productList" :key="item.pk" :label="item.name" :value="item.pk" />
            </el-select>
          </el-form-item>
          <el-form-item label="添加方式：" prop="type" class="label-feather">
            <el-radio-group v-model="form.type">
              <el-radio label="0">自动生成</el-radio>
              <el-radio label="1">上传</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="设备数量：" prop="count" v-if="form.type === '0'" class="label-feather">
            <el-input v-model="form.count" class="width500" placeholder="请输入设备数量" />
          </el-form-item>
          <el-form-item label="文件上传：" v-if="form.type === '1'" class="label-feather">
            <div class="btn-normal">
              <div class="upload-box">
                <el-upload action="" class="upload-demo" ref="upload" accept=".csv" :limit="1" :show-file-list="false"
                  :on-exceed="handleExceed" :http-request="changeUpload">
                  <template #trigger>
                    <div class="btn-upload">
                      <img src="@/assets/menu/upload.png">点击上传
                    </div>
                  </template>
                </el-upload>
                <div class="download-btn" @click="handleClick">下载模板</div>
              </div>
              <!-- 上传中 -->
              <div class="file-container" v-if="isUploading">
                <img src="@/assets/drive/file.png">
                <div>{{ isUploading }}</div>
                <img src="@/assets/drive/close.png" class="is-close" @click="handleClear">
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="info-box">
          <p class="mb20">·最大支持批量添加100个，文件大小小于50M。</p>
          <p>·设备ID即为设备标识，必须唯一，如IMEI、MAC等。如果产品为NB-IoT设备，请用IMEI</p>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { ElMessage } from "element-plus";
import { reactive, ref, defineEmits, onMounted, getCurrentInstance } from "vue";
import api from '@/api/api';
const emit = defineEmits(["close"]);
const isUploading = ref("");
const form = reactive({
  pk: "", // 所属产品
  count: "", // 设备数量
  type: "1", // 添加方式
})
const rules = {
  pk: [
    { required: true, message: "所属产品不能为空", trigger: "change" },
  ],
  count: [{ required: true, message: "设备数量不能为空", trigger: "change" }],
  type: [
    { required: true, message: "添加方式不能为空", trigger: "change" },
  ],
};
const productList = ref([]);
const formBatchDeviceAdd = ref(null);
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  getProducts();
})

const getProducts = () => {
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      productList.value = res.res.data;
    }
  })
}
const handleClose = () => {
  emit("close", false)
}

const handleClick = () => {
  let a = document.createElement("a");
  a.href = "/static/demo.csv";
  a.download = "模板.csv"; //设置下载文件文件名，这里加上.pdf指定文件类型，word文件就指定.word即可
  a.style.display = "none"; // 障眼法藏起来a标签
  document.body.appendChild(a); // 将a标签追加到文档对象中
  a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
  a.remove();
}
const handleOk = () => {
  formBatchDeviceAdd.value.validate((valid) => {
    if (valid) {
      if (form.type === '0') {
        // 自动生成
        add()
      } else {
        addByFile()
      }
    } else {
      return false;
    }
  });
}

const add = () => {
  const { pk, count } = form;
  api.batchAddDevice({
    pk,
    num: count
  }).then(res => {
    if (res.code === "0") {
      ElMessage.success("添加成功！")
      emit("close", true)
    }
  })
}

const addByFile = () => {
  if (!fileValue.value) {
    ElMessage.error("请选择上传的文件")
    return;
  }
  const { pk } = form
  api.batchAddDeviceByFile(pk, fileValue.value).then(res => {
    if (res.code === "0") {
      ElMessage.success("添加成功！")
      emit("close", true)
    }
  })
}
const fileValue = ref(null);
const changeUpload = (e) => {
  const file = e.file
  const size = file.size / (1024 * 1024); //文件大小
  if (size > 50) {
    return ElMessage.error("文件大小不能超过50M！");
  }
  let fd = new FormData();
  fd.append("file", file);
  fileValue.value = fd;
  isUploading.value = file.name
}

const handleExceed = (e) => {
  currentInstance.proxy.$refs.upload.clearFiles();
  currentInstance.proxy.$refs.upload.handleStart(e[0]);
  currentInstance.proxy.$refs.upload.submit();
  fileValue.value = ""
}

const handleClear = () => {
  fileValue.value = "";
  isUploading.value = "";
}

</script>

<style lang="less" scoped>
.mb20 {
  margin-bottom: 6px;
}

.info-box {
  margin-top: 20px;
  font-size: 14px;
  color: #5E6B86;
  background: #DDE6F3;
  box-sizing: border-box;
  padding: 12px 25px;
  border-radius: 4px;

  p {
    line-height: 20px;
  }
}

.file-container {
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .is-close {
    margin-left: 20px;
    width: 14px;
    height: 14px;
  }
}

.upload-box {
  display: flex;
  align-items: center;

  .btn-upload {
    width: 136px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #B7BBC0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 15px;
      height: 15px;
      margin-right: 4px;
    }
  }
}

.width500 {
  width: 500px;
}

.dialog-box {
  width: 700px;
}

.btn-normal {

  .download-btn {
    cursor: pointer;
    margin-left: 20px;
    color: -webkit-link;
    text-decoration: underline;
  }
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 180px;
  }
}
</style>